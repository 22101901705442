<template>
  <v-container id="user" fluid tag="section">
    <v-card>
      <v-alert color="red lighten-2" dark v-if="showEmailError">
        {{ showErrorEmail }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showPhoneNumberError">
        {{ showErrorPhoneNumber }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showVehicleNumberError">
        {{ showErrorVehicleNumber }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showDriverLicenceNumberError">
        {{ showErrorDriverLicenceNumber }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showDriverPhotoError">
        {{ showErrorDriverPhoto }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showInsurancePhotoError">
        {{ showErrorInsurancePhoto }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showLicencePhotoError">
        {{ showErrorLicencePhoto }}
      </v-alert>
      <v-form ref="form">
        <v-container class="py-2">
          <v-row>
            <v-col cols="6" md="6" v-if="customer_photo != null">
              <v-avatar>
                <v-img
                  aspect-ratio="1"
                  :src="imageUrl + customer_photo"
                  :lazy-src="imageUrl + customer_photo"
                  @click="openNewTab(imageUrl + customer_photo)"
                  style="cursor: pointer"
                />
              </v-avatar>
            </v-col>
            <v-col cols="6" md="6" v-if="editUser || onlyView">
              <v-text-field
                label="ClubGlugg Points"
                class="purple-input"
                v-model="club_glugg_points"
                :rules="inputRule"
                :disabled="true"
              />
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                label="First Name"
                class="purple-input"
                v-model="first_name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                label="Last Name"
                class="purple-input"
                v-model="last_name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>

            <v-col cols="4" md="4">
              <v-text-field
                type="number"
                max="3"
                label="Age"
                class="purple-input"
                v-model="age"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                label="Email"
                class="purple-input"
                v-model="email"
                :rules="emailRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                type="number"
                label="Phone Number"
                class="purple-input"
                v-model="phone_number"
                :rules="inputRule"
                :disabled="onlyView"
                max="10"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-select
                :items="userType"
                label="Select User Type"
                item-text="name"
                item-value="_id"
                v-model="userTypeId"
                :rules="selectRule"
                :disabled="true"
              ></v-select>
            </v-col>

            <v-col cols="6" md="6" v-if="!editUser">
              <v-text-field
                v-model="password"
                label="Password"
                hint="At least 6 characters"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                counter
                @click:append="show1 = !show1"
                :rules="passwordRule"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="submit"
                :loading="loading"
              >
                {{
                  onlyView == true
                    ? "Back"
                    : editUser == true
                    ? "Update"
                    : "Submit"
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
    <customer-tickets :tickets="ticket_list" />
    <customer-addresses :addresses="address_list" />
    <customer-order-history :customerId="id" />
  </v-container>
</template>

<script>
export default {
  components: {
    CustomerAddresses: () => import("./CustomerAddresses.vue"),
    CustomerOrderHistory: () => import("./CustomerOrderHistory.vue"),
    CustomerTickets: () => import("./CustomerTickets.vue"),
  },
  props: ["id", "action"],
  data() {
    return {
      editUser: false,
      showEmailError: false,
      showErrorEmail: "",
      showPhoneNumberError: false,
      showErrorPhoneNumber: "",
      showVehicleNumberError: false,
      showErrorVehicleNumber: "",
      showErrorDriverLicenceNumber: "",
      showDriverLicenceNumberError: false,
      showErrorDriverPhoto: "",
      showDriverPhotoError: false,
      showErrorInsurancePhoto: "",
      showInsurancePhotoError: false,
      showErrorLicencePhoto: "",
      showLicencePhotoError: false,
      stores: [],
      userType: [],
      userTypeId: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      age: null,
      isVerified: "",
      customer_photo: null,
      show1: false,
      deleteImageConfirm: "",
      deleteFieldConfirm: "",
      imageUrl: process.env.VUE_APP_Image_Link,
      deliveryGuyId: process.env.VUE_APP_Delivery_Guys_ID,
      customerId: process.env.VUE_APP_Customer_ID,
      inputRule: [(v) => !!v || "Field is required"],
      selectRule: [(v) => !!v || "Select any option"],
      passwordRule: [(v) => v.length >= 6 || "Minimum length is 6 characters"],
      imageRule: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
      emailRule: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      onlyView: false,
      loading: false,
      club_glugg_points: "0",
      address_list: [],
      ticket_list: [],
    };
  },
  methods: {
    userTypeList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "userType/getall")
        .then((response) => {
          if (response.status == 200) {
            this.userType = response.data.user_type;
            if (this.onlyView != true && this.editUser != true) {
              // for default user type while adding new user
              this.userType.forEach((element) => {
                if (element.name == "Customer") {
                  this.userTypeId = element._id;
                }
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "user/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            console.log(response.data.user);
            this.first_name = response.data.user.first_name;
            this.last_name = response.data.user.last_name;
            this.email = response.data.user.email;
            this.phone_number = response.data.user.phone_number;
            this.userTypeId = response.data.user.user_type[0]._id;
            this.club_glugg_points = response.data.user.club_glugg_points;
            this.customer_photo = response.data.user.customer_photo;
            this.age = response.data.user.age ? response.data.user.age : 0;

            this.address_list = response.data.user.address_list
              ? response.data.user.address_list
              : [];
            this.ticket_list = response.data.user.ticket_list
              ? response.data.user.ticket_list
              : [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    submit() {
      this.showEmailError = false;
      this.showPhoneNumberError = false;
      this.loading = true;
      let formData = new FormData();
      if (this.editUser == true) {
        formData.append("id", this.id);
      }
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("email", this.email);
      formData.append("phone_number", this.phone_number);
      formData.append("user_type_id", this.userTypeId);
      formData.append("password", this.password);
      formData.append("age", this.age);

      // // Display the key/value pairs
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      if (this.onlyView) {
        this.$router.push({ name: "Customers" });
      } else if (this.editUser == true) {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "user/update", formData)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.$router.push({
                name: "Customers",
                params: { edit: true },
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data.email != null) {
              this.showErrorEmail = error.response.data.email[0];
              this.showEmailError = true;
            }
            if (error.response.data.phone_number != null) {
              this.showErrorPhoneNumber = error.response.data.phone_number[0];
              this.showPhoneNumberError = true;
            }
          });
      } else {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "user/create", formData)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.$router.push({
                name: "Customers",
                params: { add: true },
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data.email != null) {
              this.showErrorEmail = error.response.data.email[0];
              this.showEmailError = true;
            }
            if (error.response.data.phone_number != null) {
              this.showErrorPhoneNumber = error.response.data.phone_number[0];
              this.showPhoneNumberError = true;
            }
          });
      }
    },
  },
  mounted() {
    const route = this.$router.currentRoute;
    this.editUser = false;
    this.userTypeList();
    if (this.id != undefined) {
      this.getUserData(this.id);
    }
    if (route.name == "View Customer") {
      this.onlyView = true;
    }
    if (route.name == "Edit Customer") {
      this.editUser = true;
    }
  },
};
</script>

<style>
</style>